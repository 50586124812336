import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { BsCart } from "react-icons/bs"; // Ikon cart dari react-icons

const links = [
  {
    name: "home",
    path: "/",
  },
  {
    name: "About",
    path: "/about",
  },
  {
    name: "Product",
    path: "/product",
  },
  {
    name: "Store",
    path: "/store",
  },
  {
    name: "Contact",
    path: "/contact",
  },
];

const Nav = () => {
  const location = useLocation();
  const pathname = location.pathname;

  // State untuk melacak jumlah item dalam cart
  const [cartItems, setCartItems] = useState(0);

  return (
    <nav className="flex gap-8 items-center">
      {links.map((link, index) => (
        <NavLink
          to={link.path}
          key={index}
          className={`${
            link.path === pathname ? "text-accent border-b-2 border-second" : ""
          } capitalize font-medium hover:text-accent transition-all`}
        >
          {link.name}
        </NavLink>
      ))}
    </nav>
  );
};

export default Nav;
