import Hdcvi1 from "../assets/produk/hdcvi_16_kamera.png";
import Hdcvi2 from "../assets/produk/hdcvi_8_kamera.png";
import Hdcvi3 from "../assets/produk/hdcvi_6_kamera.png";
import Hdcvi4 from "../assets/produk/hdcvi_4_kamera.png";
import Hdcvi5 from "../assets/produk/hdcvi_3_kamera.png";
import Hdcvi6 from "../assets/produk/hdcvi_2_kamera.png";

import Hilook1 from "../assets/produk/hilook_16_kamera.png";
import Hilook2 from "../assets/produk/hilook_8_kamera.png";
import Hilook3 from "../assets/produk/hilook_6_kamera.png";
import Hilook4 from "../assets/produk/hilook_4_kamera.png";
import Hilook5 from "../assets/produk/hilook_3_kamera.png";
import Hilook6 from "../assets/produk/hilook_2_kamera.png";

import Gambar1 from "../assets/produk/hdcvi_16_kamera.png";
import Gambar2 from "../assets/produk/hdcvi_16_kamera.png";
// Semua data produk digabungkan dalam satu file
const dataProduk = {
  HDCVI: {
    icon: "/assets/resume/badge.svg",
    title: "PAKET HDCVI",
    description: "Paket CCTV Dengan merek HDCVI",
    items: [
      {
        id: 1,
        judul: "Paket CCTV HDCVI 16 Camera + Pasang",
        deskripsi: [
          { test: "16 Camera Indoor/Outdoor 2mp 1080" },
          { test: "Uvr 16chanel HD" },
          { test: "Hardisk CCTV 2TB" },
          { test: "Kabel 240Meter" },
          { test: "Adaptor" },
          { test: "Conector BNC / DC" },
          { test: "Steker + Colokan" },
          { test: "Kabel Hdmi + Lan 1.5M" },
        ],
        termasuk: [
          { ter: "Pemasangan" },
          { ter: "Seting Online di HP" },
          { ter: "Garansi 1 tahun" },
        ],
        harga: "9.500.000",
        gambar: Hdcvi1,
      },
      {
        id: 2,
        judul: "Paket CCTV HDCVI 8 Camera + Pasang",
        deskripsi: [
          { test: "8 Camera Indoor/Outdoor 2mp 1080" },
          { test: "Uvr 8chanel HD" },
          { test: "Hardisk CCTV 1TB" },
          { test: "Kabel 120Meter" },
          { test: "Adaptor" },
          { test: "Conector BNC / DC" },
          { test: "Steker + Colokan" },
          { test: "Kabel Hdmi + Lan 1.5M" },
        ],
        termasuk: [
          { ter: "Pemasangan" },
          { ter: "Seting Online di HP" },
          { ter: "Garansi 1 tahun" },
        ],
        harga: "5.000.000",
        gambar: Hdcvi2,
      },
      {
        id: 3,
        judul: " 6 Camera Indoor/Outdoor 2mp 1080",
        deskripsi: [
          { test: "8 Camera Indoor/Outdoor 2mp 1080" },
          { test: "Uvr 8chanel HD" },
          { test: "Hardisk CCTV 1TB" },
          { test: "Kabel 90Meter" },
          { test: "Adaptor" },
          { test: "Conector BNC / DC" },
          { test: "Steker + Colokan" },
          { test: "Kabel Hdmi + Lan 1.5M" },
        ],
        termasuk: [
          { ter: "Pemasangan" },
          { ter: "Seting Online di HP" },
          { ter: "Garansi 1 tahun" },
        ],
        harga: "4.500.000",
        gambar: Hdcvi3,
      },
      {
        id: 4,
        judul: " PAKET CCTV SPC 4 CAMERA + PASANG",
        deskripsi: [
          { test: "4 Camera Indoor/Outdoor 2mp 1080" },
          { test: "Uvr 8chanel HD" },
          { test: "Hardisk CCTV 500GB" },
          { test: "Kabel 60 Meter" },
          { test: "Adaptor" },
          { test: "Conector BNC / DC" },
          { test: "Steker + Colokan" },
          { test: "Kabel Hdmi + Lan 1.5M" },
        ],
        termasuk: [
          { ter: "Pemasangan" },
          { ter: "Seting Online di HP" },
          { ter: "Garansi 1 tahun" },
        ],
        harga: " 3.000.000",
        gambar: Hdcvi4,
      },
      {
        id: 5,
        judul: " PAKET CCTV 3 CAMERA + PASANG",
        deskripsi: [
          { test: " 3 Camera Indoor/Outdoor 2mp 1080" },
          { test: "Uvr 8chanel HD" },
          { test: "Hardisk CCTV 320GB" },
          { test: "Kabel 45 Meter" },
          { test: "Adaptor" },
          { test: "Conector BNC / DC" },
          { test: "Steker + Colokan" },
          { test: "Kabel Hdmi + Lan 1.5M" },
        ],
        termasuk: [
          { ter: "Pemasangan" },
          { ter: "Seting Online di HP" },
          { ter: "Garansi 1 tahun" },
        ],
        harga: " 2.500.000",
        gambar: Hdcvi5,
      },
      {
        id: 6,
        judul: "PAKET CCTV 2 CAMERA + PASANG",
        deskripsi: [
          { test: "2 Camera Indoor/Outdoor 2mp 1080" },
          { test: "Uvr 8chanel HD" },
          { test: "Hardisk CCTV 320GB" },
          { test: "Kabel 30 Meter" },
          { test: "Adaptor" },
          { test: "Conector BNC / DC" },
          { test: "Steker + Colokan" },
          { test: "Kabel Hdmi + Lan 1.5M" },
        ],
        termasuk: [
          { ter: "Pemasangan" },
          { ter: "Seting Online di HP" },
          { ter: "Garansi 1 tahun" },
        ],
        harga: " 1.800.000",
        gambar: Hdcvi6,
      },
    ],
  },

  HILOOK: {
    icon: "/assets/resume/badge.svg",
    title: "PAKET HDCVI",
    description: "Paket CCTV Dengan merek HDCVI",
    items: [
      {
        id: 1,
        judul: "PAKET CCTV 16 CAMERA + PASANG",
        deskripsi: [
          { test: "16 Camera Indoor/Outdoor 2mp 1080" },
          { test: "hai" },
        ],
        termasuk: [{ ter: "hai" }],
        harga: "9.500.000",
        gambar: Gambar1,
      },
      {
        id: 2,
        judul: "Paket CCTV HDCVI 8 Camera + Pasang",
        deskripsi: [
          { test: "16 Camera Indoor/Outdoor 2mp 1080" },
          { test: "hai" },
        ],
        termasuk: [{ ter: "hai" }],
        harga: "9.500.000",
        gambar: Gambar2,
      },
    ],
  },

  HIKVISION: {
    icon: "/assets/resume/badge.svg",
    title: "PAKET HDCVI",
    description: "Paket CCTV Dengan merek HDCVI",
    items: [
      {
        id: 1,
        judul: "Paket CCTV HDCVI 16 Camera + Pasang",
        deskripsi: [
          { test: "16 Camera Indoor/Outdoor 2mp 1080" },
          { test: "hai" },
        ],
        termasuk: [{ ter: "hai" }],
        harga: "9.500.000",
        gambar: Gambar1,
      },
      {
        id: 2,
        judul: "Paket CCTV HDCVI 8 Camera + Pasang",
        deskripsi: [
          { test: "16 Camera Indoor/Outdoor 2mp 1080" },
          { test: "hai" },
        ],
        termasuk: [{ ter: "hai" }],
        harga: "9.500.000",
        gambar: Gambar2,
      },
    ],
  },

  DAHUA: {
    icon: "/assets/resume/badge.svg",
    title: "PAKET HDCVI",
    description: "Paket CCTV Dengan merek HDCVI",
    items: [
      {
        id: 1,
        judul: "Paket CCTV HDCVI 16 Camera + Pasang",
        deskripsi: [
          { test: "16 Camera Indoor/Outdoor 2mp 1080" },
          { test: "hai" },
        ],
        termasuk: [{ ter: "hai" }],
        harga: "9.500.000",
        gambar: Gambar1,
      },
      {
        id: 2,
        judul: "Paket CCTV HDCVI 8 Camera + Pasang",
        deskripsi: [
          { test: "16 Camera Indoor/Outdoor 2mp 1080" },
          { test: "hai" },
        ],
        termasuk: [{ ter: "hai" }],
        harga: "9.500.000",
        gambar: Gambar2,
      },
    ],
  },

  Wcamera: {
    icon: "/assets/resume/badge.svg",
    title: "PAKET HDCVI",
    description: "Paket CCTV Dengan merek HDCVI",
    items: [
      {
        id: 1,
        judul: "Paket CCTV HDCVI 16 Camera + Pasang",
        deskripsi: [
          { test: "16 Camera Indoor/Outdoor 2mp 1080" },
          { test: "hai" },
        ],
        termasuk: [{ ter: "hai" }],
        harga: "9.500.000",
        gambar: Gambar1,
      },
      {
        id: 2,
        judul: "Paket CCTV HDCVI 8 Camera + Pasang",
        deskripsi: [
          { test: "16 Camera Indoor/Outdoor 2mp 1080" },
          { test: "hai" },
        ],
        termasuk: [{ ter: "hai" }],
        harga: "9.500.000",
        gambar: Gambar2,
      },
    ],
  },
  // Tambahkan data produk lainnya di sini
};

export default dataProduk;
