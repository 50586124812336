import React from "react";
import { TypeAnimation } from "react-type-animation";
import { motion } from "framer-motion";
import Client from "./Client";

const Testimonial = () => {
  return (
    <div>
      <section className="container">
        <div className="container rounded-md bg-accent ">
          <h1 className="h1 text-center text-white">
            <motion.div
              className="inline-block" // Tambahkan class inline-block di sini
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
            >
              <p>
                {" "}
                <TypeAnimation
                  sequence={[
                    "Testimonial",
                    1000,
                    "Testimonial",
                    1000,
                    "Testimonial",
                    1000,
                  ]}
                  wrapper="span"
                  speed={30}
                  repeat={Infinity}
                />
              </p>
            </motion.div>
          </h1>
        </div>

        <div className="bg-white rounded-lg backdrop-blur-md">
          <Client />
        </div>
      </section>
    </div>
  );
};

export default Testimonial;
