import React from "react";
import Layout from "./Layout";

import Logo1 from "../assets/Gambar/lmao.png";
import { Button } from "../components/ui/button";
import gambar from "../assets/Gambar/gambar2.png";
import { TypeAnimation } from "react-type-animation";
import { motion } from "framer-motion";

const story = [
  {
    judul: "1",
    deskripsi: "Memberikan sistem keamanan NO.1 untuk pelanggan",
  },
  {
    judul: "2",
    deskripsi: "Menyediakan produk yang berinovasi dan system terdepan",
  },
  {
    judul: "3",
    deskripsi: "Memastikan standar kualitas tertinggi pada produk dan layanan",
  },
  {
    judul: "4",
    deskripsi: "Memberikan kenyamanan untuk sistem keamanan seluruh pelanggan",
  },
];

const Store = () => {
  return (
    <Layout>
      {/* About section1 */}
      <section className="container">
        <div className="container rounded-md">
          <div className="grid grid-cols-1 xl:grid-cols-2 w-full gap-4">
            {/* Malang */}
            <div className="flex flex-col justify-center items-center p-4 border-4 rounded-md backdrop-blur-md">
              {/* Peta Google Maps */}
              <div className="mb-4">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15806.355787301334!2d112.627764!3d-7.937925999999999!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd629e33ed40225%3A0x9ad7bcb4317c14aa!2sOXYGEN%20CCTV%20%3A%20Distributor%20%26%20Grosir%20CCTV%20Malang!5e0!3m2!1sid!2sid!4v1728556907773!5m2!1sid!2sid"
                  style={{ border: 0 }}
                  allowFullScreen
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>

              {/* Alamat dengan border */}
              <div className="p-4 border-2 border-gray-300 rounded-md text-center w-full">
                <p>OXYGEN CCTV: Distributor & Grosir CCTV Malang</p>
                <p>Jalan XYZ No.123, Malang, Jawa Timur</p>
              </div>
            </div>

            {/* Sidoarjo */}
            <div className="flex flex-col justify-center items-center p-4 border-4 rounded-md backdrop-blur-md">
              {/* Peta Google Maps */}
              <div className="mb-4">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15806.355787301334!2d112.627764!3d-7.937925999999999!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd629e33ed40225%3A0x9ad7bcb4317c14aa!2sOXYGEN%20CCTV%20%3A%20Distributor%20%26%20Grosir%20CCTV%20Malang!5e0!3m2!1sid!2sid!4v1728556907773!5m2!1sid!2sid"
                  style={{ border: 0 }}
                  allowFullScreen
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>

              {/* Alamat dengan border */}
              <div className="p-4 border-2 border-gray-300 rounded-md text-center w-full">
                <p>OXYGEN CCTV: Distributor & Grosir CCTV Malang</p>
                <p>Jalan XYZ No.123, Malang, Jawa Timur</p>
              </div>
            </div>
            {/* Blitar */}
            <div className="flex flex-col justify-center items-center p-4 border-4 rounded-md backdrop-blur-md">
              {/* Peta Google Maps */}
              <div className="mb-4">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15806.355787301334!2d112.627764!3d-7.937925999999999!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd629e33ed40225%3A0x9ad7bcb4317c14aa!2sOXYGEN%20CCTV%20%3A%20Distributor%20%26%20Grosir%20CCTV%20Malang!5e0!3m2!1sid!2sid!4v1728556907773!5m2!1sid!2sid"
                  style={{ border: 0 }}
                  allowFullScreen
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>

              {/* Alamat dengan border */}
              <div className="p-4 border-2 border-gray-300 rounded-md text-center w-full">
                <p>OXYGEN CCTV: Distributor & Grosir CCTV Malang</p>
                <p>Jalan XYZ No.123, Malang, Jawa Timur</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Store;
