import React, { useState } from "react";
import { Sheet, SheetContent, SheetTrigger } from "../components/ui/sheet";
import { NavLink, useLocation } from "react-router-dom";
import { CiMenuFries } from "react-icons/ci";

const links = [
  {
    name: "home",
    path: "/",
  },
  {
    name: "About",
    path: "/about",
  },
  {
    name: "Product",
    path: "/product",
  },
  {
    name: "Store",
    path: "/store",
  },
  {
    name: "Contact",
    path: "/contact",
  },
];

const MobileNav = () => {
  const location = useLocation();
  const pathname = location.pathname;

  return (
    <Sheet>
      <SheetTrigger className="flex justify-center items-center">
        <CiMenuFries className="text-[32px] text-white" />
      </SheetTrigger>
      <SheetContent className="flex flex-col">
        {/* Bagian Logo */}
        <div className="flex justify-center mt-10 items-center text-2xl font-second">
          <NavLink to="/"></NavLink>
        </div>

        {/* Bagian Navigasi */}
        <nav className="flex flex-col justify-center items-center gap-8 mt-8">
          {links.map((link, index) => (
            <NavLink to={link.path} key={index}>
              <a
                className={`text-xl capitalize text-white ${
                  link.path === pathname
                    ? "text-primary border-b-2 border-accent"
                    : "hover:text-second"
                } transition-all`}
              >
                {link.name}
              </a>
            </NavLink>
          ))}
        </nav>
      </SheetContent>
    </Sheet>
  );
};

export default MobileNav;
