import React from "react";
import Layout from "./Layout";
import { FiDownload } from "react-icons/fi";
import { Button } from "../components/ui/button";
import Social from "../components/Social";
import Card from "../components/Card";
import gambar from "../assets/Gambar/gambar2.png";
import Testimonial from "../components/Testimonial";

function Home() {
  return (
    <Layout>
      <section className="h-full">
        <div className="container">
          <div
            className="flex flex-col xl:flex-row items-center justify-between xl:pt-8 
        xl:pb-24"
          >
            {/*bagian teks*/}
            <div className="text-center xl:text-left order-2 xl:order-none">
              <span className="text-xl">Wellcome to</span>
              <h1 className="h1">
                <span className="text-accent">AKSES CCTV</span>
              </h1>
              <p className="max-w-[500px] mb-9 text-white/80">
                OXYGEN CCTV adalah perusahaan Yang bergerak di bidang Security
                Sistem, kami melayani instalasi cctv dengan cover area
                jawatimur: Malang, Surabaya, Kediri, Blitar, Tulungagung,
                Jombang, Mojokerto, Nganjuk, Sidoarjo, Pasuruan, Probolinggo,
                Lumajang dan beberapa kota lain nya
              </p>
              {/*Tombol sosial media*/}
              <div className="flex flex-col xl:flex-row items-center gap-8">
                <a download="My_CV.pdf">
                  <Button
                    variant="outline"
                    size="lg"
                    className="uppercase flex items-center gap-2"
                  >
                    <span>Download Browsur</span>
                    <FiDownload className="text-xl" />
                  </Button>
                </a>
                <div className="mb-8 xl:mb-0">
                  <Social
                    containerStyles="flex gap-6"
                    iconStyles=" w-9 h-9 border
                border-accent rounded-full flex justify-center items-center text-accent
                text-base hover:bg-accent hover:text-primary 
                hover:transition-all duration-500 "
                  />
                </div>
              </div>
            </div>
            {/*bagian photo*/}
            <div className="order-1 xl:order-none mb-8 xl:mb-0 xl:w-[400px] w-[200px]">
              <img
                src={gambar}
                priority
                quality={100}
                fill
                alt=""
                className="object-contain"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Bagian Course */}
      <section className="">
        <div className="rounded-md">
          <Card />
        </div>
      </section>

      {/* Bagian Testimonial */}
      <section className="">
        <div className="rounded-md">
          <Testimonial />
        </div>
      </section>
    </Layout>
  );
}

export default Home;
