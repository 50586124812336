import React, { useState } from "react";
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import { Textarea } from "../components/ui/textarea";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../components/ui/select";
import { motion } from "framer-motion";
import { FaPhoneAlt, FaEnvelope, FaMapMarkedAlt } from "react-icons/fa";

const info = [
  {
    icon: <FaPhoneAlt />,
    title: "Phone",
    description: "(+62) 818-0507-8508 ",
  },
  {
    icon: <FaEnvelope />,
    title: "Email",
    description: "oxygen_cctv@gmail.com",
  },
  {
    icon: <FaMapMarkedAlt />,
    title: "OXYGEN CCTV MALANG",
    jam: "SENIN- SABTU 09:00 - 17:00",
    description: "JL.SUKARNO HATTA No.83 RUKO KAV.E MALANG",
  },
  {
    icon: <FaMapMarkedAlt />,
    title: "OXYGEN CCTV SIDOARJO",
    jam: "SENIN- SABTU 09:00 - 17:00",
    description: "JL.KH.HAMDANI No.4 BUDURAN, SIDOARJO",
  },
  {
    icon: <FaMapMarkedAlt />,
    title: "OXYGEN CCTV BLITAR",
    jam: "SENIN- SABTU 09:00 - 17:00",
    description: "JL.BENGAWAN SOLO No.58 PAKUNDEN BLITAR",
  },
];

const ContactCom = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSelectChange = (value) => {
    setFormData({ ...formData, service: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { firstName, lastName, email, phone, service, message } = formData;
    const whatsappMessage = `Nama: ${firstName} ${lastName}\nEmail: ${email}\nPhone: ${phone}\nPesan: ${message}`;
    const whatsappUrl = `https://wa.me/00000000000?text=${encodeURIComponent(
      whatsappMessage
    )}`;
    window.open(whatsappUrl, "_blank");
  };

  return (
    <motion.section
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
        transition: { delay: 2.4, duration: 0.4, ease: "easeIn" },
      }}
      className="py-6"
    >
      <div className="container mx-auto">
        <div className="flex flex-col xl:flex-row gap-[30px]">
          <div className="xl:w-[54%] order-2 xl:order-none">
            <form
              className="flex flex-col gap-6 p-10 bg-white rounded-xl"
              onSubmit={handleSubmit}
            >
              <h3 className="text-4xl text-second font-bold">
                Dapatkan Penawaran Terbaik Kami
              </h3>
              <p className="text-black/60 text-[9px] md:text-[12px] xl:text-[15px]">
                CS kami akan menjawab semua kebutuhan Anda dalam memilih sistem
                keamanan terbaik. Dari rekomendasi produk hingga pemasangan CCTV
                profesional, kami siap memberikan solusi yang tepat untuk
                melindungi properti Anda. Dapatkan layanan cepat, ramah, dan
                terpercaya hanya di AKSESS CCTV kami, di mana kepuasan dan
                keamanan Anda adalah prioritas utama!
              </p>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <Input
                  type="text"
                  name="firstName"
                  placeholder="First name"
                  onChange={handleChange}
                  className="text-white"
                />

                <Input
                  type="text"
                  name="lastName"
                  placeholder="Last name"
                  onChange={handleChange}
                  className="text-white"
                />
                <Input
                  type="email"
                  name="email"
                  placeholder="Email address"
                  onChange={handleChange}
                  className="text-white"
                />
                <Input
                  type="tel"
                  name="phone"
                  placeholder="Phone Number"
                  onChange={handleChange}
                  className="text-white"
                />
              </div>
              <Textarea
                className="h-[200px] text-white"
                name="message"
                placeholder="Type your message here."
                onChange={handleChange}
              />
              <Button
                variant="destructive2"
                type="submit"
                className="max-w-40  "
              >
                Send message
              </Button>
            </form>
          </div>

          <div className="flex-1 flex items-center xl:justify-end order-1 xl:order-none mb-8 xl:mb-0">
            <div className="grid grid-cols-1 gap-4">
              {info.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="flex items-center gap-6 bg-primary p-2 rounded-md
                      border-4"
                  >
                    <div className="w-[52px] h-[52px] bg-[#27272c] text-second rounded-md flex items-center justify-center">
                      <div>{item.icon}</div>
                    </div>
                    <div className="flex-1">
                      <p className="h3 w-full text-sm break-words">
                        {item.title}
                      </p>
                      <h3 className="h4 w-full text-sm break-words whitespace-normal">
                        {item.jam}
                      </h3>
                      <h3 className="h4 w-full text-sm break-words whitespace-normal">
                        {item.description}
                      </h3>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </motion.section>
  );
};

export default ContactCom;
