import React from "react";
import { NavLink } from "react-router-dom";
import Logo from "../assets/Logo/Logo2.png";

import Nav from "./Nav";
import MobileNav from "./MobileNav";
const Header = () => {
  return (
    <header className="">
      <div className="container mx-auto flex justify-between items-center">
        <NavLink to={"/"} className="flex gap-2 items-center cursor-pointer">
          <img src={Logo} alt="" className=" w-[190px]" />
          {/* <h1 className=" text-4xl font-semibold font-second text-accent ">
            Tanimo<span className="text-second">.id</span>{" "}
          </h1> */}
        </NavLink>

        <div className="hidden xl:flex items-center gap-8">
          <Nav />
        </div>

        <div className="xl:hidden">
          <MobileNav />
        </div>
      </div>
    </header>
  );
};

export default Header;
