import React from "react";
import Layout from "./Layout";

import Logo1 from "../assets/Gambar/lmao.png";
import { Button } from "../components/ui/button";
import gambar from "../assets/Gambar/gambar2.png";
import { TypeAnimation } from "react-type-animation";
import { motion } from "framer-motion";

const story = [
  {
    judul: "1",
    deskripsi: "Memberikan sistem keamanan NO.1 untuk pelanggan",
  },
  {
    judul: "2",
    deskripsi: "Menyediakan produk yang berinovasi dan system terdepan",
  },
  {
    judul: "3",
    deskripsi: "Memastikan standar kualitas tertinggi pada produk dan layanan",
  },
  {
    judul: "4",
    deskripsi: "Memberikan kenyamanan untuk sistem keamanan seluruh pelanggan",
  },
];

const About = () => {
  return (
    <Layout>
      <section className="h-full w-full">
        <div className="container w-full">
          <div className="flex items-center justify-center xl:justify-between xl:pt-8 xl:pb-24 w-full">
            {/*bagian teks*/}
            <div className="text-center xl:text-left xl:order-none w-full">
              <h1 className="h1">
                <span className="text-accent">AKSES CCTV</span>
              </h1>
              <p className="mb-3 text-white/80 w-full">
                OXYGEN CCTV adalah perusahaan Yang bergerak di bidang Security
                Sistem, kami melayani instalasi cctv dengan cover area
                jawatimur: Malang, Surabaya, Kediri, Blitar, Tulungagung,
                Jombang, Mojokerto, Nganjuk, Sidoarjo, Pasuruan, Probolinggo,
                Lumajang dan beberapa kota lain nya
              </p>
              <p className="mb-3 text-white/80 w-full">
                Tumbuh berkembang secara signifikan dan konsisten untuk menjadi
                Instaler CCTV No.1 di Jawa timur melalui kerja keras, komitmen,
                dedikasi, dan inovasi dalam system CCTV brand ternama dengan
                produk berkualitas.
              </p>
              <p className="mb-3 text-white/80 w-full">
                Kami melayani Jasa Pemasangan CCTV, Maintenance CCTV, Service
                CCTV untuk Kantor Rumah Toko Instansi Sekolah Kampus RumahSakit
                dan lain nya dengan Kualitas terbaik dan harga bersaing,
                percayakan Kebutuhan CCTV anda pada kami dengan pengalaman kami
                selama 4 tahun di bidang security sistem
              </p>
              {/*Tombol sosial media*/}
            </div>
            {/*bagian photo*/}
          </div>
        </div>
      </section>

      {/* About section1 */}
      <section className="container">
        <div className="container rounded-md">
          <div className="grid grid-cols-1 xl:grid-cols-2 w-full gap-4">
            <div className="flex justify-center items-center  p-4 border-4 rounded-md backdrop-blur-md">
              <img src={Logo1} alt="" className="rounded-md" />
            </div>

            <div className="justify-center w-full items-center">
              <h1 className="h2 text-second bg-white rounded-md pb-2 pt-2 mb-2 text-center font-bold ">
                - VISI KAMI
              </h1>
              <div className="flex flex-col gap-2">
                <p>
                  Menjadi perusahaan sistem keamanan no 1 di jawa timur dan
                  sebagai mitra terbaik pelanggan dengan menyediakan pelayanan
                  yang memuaskan dan kualitas produk terbaik dengan harga
                  standart di kelasnya
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* About section2 */}
      <section className="container">
        <div className="container rounded-md">
          <div className="grid grid-cols-1 xl:grid-cols-2 w-full gap-4">
            <div>
              <h1 className="h2 text-second bg-white rounded-md pb-2 pt-2 mb-2 text-center font-bold ">
                - MISI KAMI
              </h1>
              <div className="flex flex-col gap-2">
                {story.map((item, index) => (
                  <div
                    key={index}
                    className="bg-white rounded-md p-4 flex flex-row items-center justify-center gap-2"
                  >
                    <p className="text-black text-left">{item.deskripsi}</p>
                  </div>
                ))}
              </div>
            </div>
            <div className="p-4 border-4 rounded-md backdrop-blur-md"></div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default About;
