import React, { useState } from "react";
import { FaWhatsapp } from "react-icons/fa";
import Header from "../components/Header";
import PageTransition from "../components/PageTransition";
import StairTransition from "../components/StairTransition";
import Footer from "../components/Footer";

const Layout = ({ children }) => {
  const [showDialog, setShowDialog] = useState(false); // State untuk mengontrol tampilan alert dialog
  const [isAnimating, setIsAnimating] = useState(false); // State untuk mengontrol animasi keluar

  const handleClick = () => {
    setIsAnimating(true); // Memulai animasi ketika ikon WhatsApp di klik
    setShowDialog(true);
  };

  const closeDialog = () => {
    setIsAnimating(false); // Memulai animasi keluar
    setTimeout(() => setShowDialog(false), 500); // Delay sesuai durasi animasi
  };

  return (
    <React.Fragment>
      <Header />
      <StairTransition />
      <PageTransition>{children}</PageTransition>
      <Footer />

      {/* WhatsApp Icon */}
      <button
        onClick={handleClick}
        className="fixed bottom-4 right-4 bg-green-500 text-white p-3 rounded-full shadow-lg md:p-4 transform transition-transform duration-500 hover:scale-110 active:scale-95"
      >
        <FaWhatsapp size={28} className="md:text-4xl" />
      </button>

      {/* Alert Dialog */}
      {showDialog && (
        <div
          className={`fixed inset-0 flex items-center justify-center z-50 ${
            isAnimating ? "opacity-100 scale-100" : "opacity-0 scale-95"
          } transition-all duration-500 ease-out`}
        >
          <div
            onClick={closeDialog}
            className="fixed inset-0 bg-black opacity-30 transition-opacity duration-500"
          ></div>
          <div
            className={`bg-white p-6 rounded-lg shadow-lg w-11/12 max-w-md mx-auto text-center z-10 transform transition-all duration-500 ease-out ${
              isAnimating ? "scale-100 opacity-100" : "scale-95 opacity-0"
            }`}
          >
            <h2 className="text-lg font-bold mb-4 text-black">Hubungi Kami</h2>
            <p className="text-black">
              Apakah kamu ingin menghubungi kami melalui WhatsApp?
            </p>
            <div className="mt-6 flex justify-around">
              <button
                onClick={closeDialog}
                className="bg-gray-300 text-gray-800 px-4 py-2 rounded-md transform transition-transform duration-500 hover:scale-105 active:scale-95"
              >
                Batal
              </button>
              <a
                href="https://wa.me/1234567890"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-green-500 text-white px-4 py-2 rounded-md transform transition-transform duration-500 hover:scale-105 active:scale-95"
              >
                Hubungi
              </a>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Layout;
