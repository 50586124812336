import React from "react";
import dataProduk from "../../data/Data2";
import { ScrollArea } from "../../components/ui/scroll-area";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../components/ui/alert-dialog";
import { NavLink } from "react-router-dom";
const nomorWhatsApp = "6281234567890";

const hdcvi = () => {
  return (
    <div>
      <div className="flex flex-col gap-[30px] text-center xl:text-left">
        <h3 className="text-4xl font-bold text-accent">
          {dataProduk.HDCVI.title}
        </h3>
        <p className="max-w-[600px] text-white/60 mx-auto xl:mx-0">
          {dataProduk.HDCVI.description}
        </p>
        <ScrollArea className="h-[500px]">
          <ul className="grid grid-cols-2 lg:grid-cols-3 gap-[30px]">
            {dataProduk.HDCVI.items.map((item, index) => {
              return (
                <li
                  key={index}
                  className="bg-[#232329] py-4 px-4 rounded-xl flex flex-col justify-center items-center lg:items-start gap-1"
                >
                  <div className="w-full h-auto max-w-[250px] flex justify-center items-center">
                    <img
                      src={item.gambar}
                      alt={item.judul}
                      className="w-full h-auto object-contain sm:w-[150px] md:w-[150px]"
                    />
                  </div>

                  <span className="text-accent text-sm lg:text-[17px]">
                    {item.judul}
                  </span>
                  <h3 className="text-md lg:text-xl max-w-[260px] min-h-[60px] text-center lg:text-left">
                    Rp: {item.harga}
                  </h3>
                  <div className="grid grid-cols-2 w-full text-center gap-4 font-bold">
                    <div className="bg-accent rounded-md w-full flex justify-center">
                      <NavLink
                        href={item.href}
                        className="w-[70px] rounded-full bg-second
      group-hover:bg-accent transition-all duration-500 
      flex justify-center items-center hover:-rotate-45"
                      >
                        <AlertDialog>
                          <AlertDialogTrigger>
                            <div className="text-md">Lihat</div>
                          </AlertDialogTrigger>
                          <AlertDialogContent>
                            <AlertDialogHeader>
                              <AlertDialogTitle>
                                <div className="text-second">{item.judul}</div>
                              </AlertDialogTitle>
                              <AlertDialogDescription className="text-left">
                                {/* Menampilkan deskripsi sebagai daftar */}
                                <div>
                                  <h4 className="font-semibold">
                                    Harga: {item.harga}
                                  </h4>
                                </div>
                                <div>
                                  <h4 className="font-semibold">Deskripsi:</h4>
                                  <ul className="list-disc pl-5">
                                    {item.deskripsi.map((desc, index) => (
                                      <li key={index}>{desc.test}</li>
                                    ))}
                                  </ul>
                                </div>
                                {/* Menampilkan termasuk sebagai daftar */}
                                <div>
                                  <h4 className="font-semibold">Termasuk:</h4>
                                  <ul className="list-disc pl-5">
                                    {item.termasuk.map((inc, index) => (
                                      <li key={index}>{inc.ter}</li>
                                    ))}
                                  </ul>
                                </div>
                              </AlertDialogDescription>
                            </AlertDialogHeader>
                            <AlertDialogFooter>
                              <AlertDialogCancel>Kembali</AlertDialogCancel>
                              <AlertDialogAction>
                                <NavLink
                                  to="#"
                                  onClick={() => {
                                    const whatsappMessage = encodeURIComponent(
                                      item.judul
                                    );
                                    const whatsappUrl = `https://wa.me/6288707493036?text=${whatsappMessage}`;
                                    window.open(whatsappUrl, "_blank");
                                  }}
                                  className="text-blue-500 hover:underline"
                                >
                                  Pesan
                                </NavLink>
                              </AlertDialogAction>
                            </AlertDialogFooter>
                          </AlertDialogContent>
                        </AlertDialog>
                      </NavLink>
                    </div>

                    <div className="bg-accent rounded-md w-full flex justify-center">
                      <NavLink
                        to="#"
                        onClick={() => {
                          const whatsappMessage = encodeURIComponent(
                            item.judul
                          );
                          const whatsappUrl = `https://wa.me/${nomorWhatsApp}?text=${whatsappMessage}`;
                          window.open(whatsappUrl, "_blank");
                        }}
                        className="w-[70px] rounded-full bg-second
      group-hover:bg-accent transition-all duration-500 
      flex justify-center items-center hover:-rotate-45"
                      >
                        <div>Pesan</div>
                      </NavLink>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </ScrollArea>
      </div>
    </div>
  );
};

export default hdcvi;
