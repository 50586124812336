import * as React from "react";

import { Card, CardContent, CardFooter } from "../components/ui/card";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../components/ui/carousel";

import Gambar from "../assets/Avatar/avatar2.png";

const data = [
  {
    num: "01",
    name: "Susi Susantii",
    gambar: Gambar,
    deskripsi: "respon cepat, harga terjangkau, admin ramah, produk banyak",
  },
  {
    num: "01",
    name: "driofff",
    gambar: Gambar,
    deskripsi: "kualitas mantap, respon cepat, harga murah",
  },
  {
    num: "01",
    name: "Rizky Rawr",
    gambar: Gambar,
    deskripsi: "Barang Berkualitas, harga tidak menindas",
  },
  {
    num: "01",
    name: "refina",
    gambar: Gambar,
    deskripsi: "toko CCTV terlengkap di malang",
  },
  {
    num: "01",
    name: "JAJA",
    gambar: Gambar,
    deskripsi: "Barang Berkualitas",
  },
  {
    num: "01",
    name: "	ADI FIRMAN",
    gambar: Gambar,
    deskripsi: "Pelayanan Baik, Harga Bersahabat",
  },
];

const Client = () => {
  return (
    <div className="container bg-[#170b0a]">
      <div className="container justify-center items-center flex flex-col">
        <Carousel
          opts={{
            align: "start",
          }}
          className="w-full max-w-2xl"
        >
          <CarouselContent>
            {data.map((data, index) => {
              return (
                <CarouselItem className="md:basis-1/2 lg:basis-1/3">
                  <div className="p-1">
                    <Card>
                      <CardContent className="flex aspect-square items-center justify-center p-6">
                        <div className="flex flex-col justify-center items-center">
                          <img src={data.gambar} alt="" />
                          <span className="text-xl font-semibold">
                            {data.name}
                          </span>
                          <p className="text-xs">{data.deskripsi}</p>
                        </div>
                      </CardContent>
                    </Card>
                  </div>
                </CarouselItem>
              );
            })}
          </CarouselContent>
          <div className="pt-10 items-center justify-center flex gap-5">
            <CarouselPrevious />
            <CarouselNext />
          </div>
        </Carousel>
      </div>
    </div>
  );
};

export default Client;
