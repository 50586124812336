import React from "react";

import Gambar1 from "../assets/Gambar/baner1.png";
import Gambar2 from "../assets/Gambar/baner2.png";
import Gambar3 from "../assets/Gambar/baner3.png";
import Gambar4 from "../assets/Gambar/baner4.png";

const dataproduk = [
  {
    nama: "Paket Kamera CCTV HIKVISION",
    deskripsi: "Paket pemasangan kamera CCTV dengan merek HIKVISION",
    url: Gambar1,
    harga: "2.500.000",
    harga_a: "11.500.000",
    star: "5.5",
  },
  {
    nama: "Paket Kamera CCTV HiLOOK",
    deskripsi: "Paket pemasangan kamera CCTV dengan merek HiLOOK",
    url: Gambar2,
    harga: "2.250.000",
    harga_a: "10.500.000",
    star: "5.5",
  },
  {
    nama: "Paket Kamera CCTV HDCVI",
    deskripsi: "Paket pemasangan kamera CCTV dengan merek HDCVI",
    url: Gambar3,
    harga: "1.800.000",
    harga_a: "9.500.000",
    star: "5.5",
  },
  {
    nama: "Paket Kamera CCTV DAHUA",
    deskripsi: "Paket pemasangan kamera CCTV dengan merek DAHUA",
    url: Gambar4,
    harga: "1.800.000",
    harga_a: "9.500.000",
    star: "5.5",
  },
];

const Card = () => {
  return (
    <section className="container grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-3">
      {dataproduk.map((data, index) => {
        return (
          <div className="flex flex-col rounded-xl bg-[#434a45] bg-clip-border text-white">
            <div className="mx-4 mt-4 overflow-hidden rounded-xl bg-blue-gray-500 bg-clip-border text-white">
              <img src={data.url} alt="ui/ux review check" />
            </div>
            <div className="p-6">
              <div className="mb-3 flex items-center justify-between">
                <h5 className="block font-sans text-xl font-medium leading-snug tracking-normal text-blue-gray-900 antialiased">
                  {data.nama}
                </h5>
                <p className="flex items-center gap-1.5 font-sans text-base font-normal leading-relaxed text-blue-gray-900 antialiased">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    aria-hidden="true"
                    className="-mt-0.5 h-5 w-5 text-yellow-700"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  {data.star}
                </p>
              </div>

              <h5 className="block font-sans text-md font-medium leading-snug tracking-normal text-blue-gray-900 antialiased">
                Rp. {data.harga} - Rp. {data.harga_a}
              </h5>

              <p className="text-xs lg:text-sm">{data.deskripsi}</p>

              <p className="block font-sans text-base font-light leading-relaxed text-gray-700 antialiased"></p>
              <div class="pt-3">
                <button
                  class="block border-t-2 w-full select-none rounded-lg py-3.5 px-7 text-center align-middle font-sans text-xs md:text-sm font-bold uppercase text-white shadow-md shadow-red-500 transition-all hover:shadow-lg hover:shadow-blue-400 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                  type="button"
                  data-ripple-light="true"
                >
                  Lihat
                </button>
              </div>
            </div>
          </div>
        );
      })}
    </section>
  );
};

export default Card;
