import React from "react";
import { NavLink } from "react-router-dom";

import { FaWhatsapp, FaInstagram, FaFacebook } from "react-icons/fa";

const socials = [
  {
    icon: <FaWhatsapp />,
    path: "",
  },
  {
    icon: <FaInstagram />,
    path: "",
  },
  {
    icon: <FaFacebook />,
    path: "",
  },
];

const Social = ({ containerStyles, iconStyles }) => {
  return (
    <div className={containerStyles}>
      {socials.map((item, index) => {
        return (
          <NavLink key={index} to={item.path} className={iconStyles}>
            {item.icon}
          </NavLink>
        );
      })}
    </div>
  );
};

export default Social;
