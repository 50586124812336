import React, { useState } from "react";
import Layout from "./Layout";

import Hdcvi from "../components/ProductCard/hdcvi";
import Hilook from "../components/ProductCard/hilook";
import Hikvision from "../components/ProductCard/hikvision";
import Dahua from "../components/ProductCard/dahua";
import Wcamera from "../components/ProductCard/wcamera";

import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../components/ui/tabs";
import { motion } from "framer-motion";

const Product = () => {
  return (
    <Layout>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{
          opacity: 1,
          transition: {
            delay: 2.4,
            duration: 0.4,
            ease: "easeIn",
          },
        }}
        className="min-h-[80vh] flex items-center justify-center py-12 xl:py-0"
      >
        <div className="container mx-auto">
          <Tabs
            defaultValue="hdcvi"
            className="flex flex-col xl:flex-row gap-[60px]"
          >
            <TabsList className="grid grid-cols-2 sm:grid-cols-1 w-full max-w-[380px] mx-auto xl:mx-0 gap-6 ">
              <TabsTrigger value="hdcvi">
                <div className="text-[12px] md:text-[16px] xl:text-[18px]">
                  PAKET CCTV HDCVI
                </div>
              </TabsTrigger>
              <TabsTrigger value="hilook">
                <div className="text-[12px] md:text-[16px] xl:text-[18px]">
                  PAKET CCTV HILOOK
                </div>
              </TabsTrigger>
              <TabsTrigger value="hikvision">
                <div className="text-[9px] md:text-[16px] xl:text-[18px]">
                  PAKET CCTV HIKVISION
                </div>
              </TabsTrigger>
              <TabsTrigger value="dahua">
                <div className="text-[12px] md:text-[16px] xl:text-[18px]">
                  PAKET CCTV DAHUA
                </div>
              </TabsTrigger>
              <TabsTrigger value="wcamera">
                <div className="text-[12px] md:text-[16px] xl:text-[18px]">
                  WIRELESS CAMERA
                </div>
              </TabsTrigger>
              <TabsTrigger value="wcamera">
                <div className="text-[12px] md:text-[16px] xl:text-[18px]">
                  CAMERA ANALOG / IP
                </div>
              </TabsTrigger>
              <TabsTrigger value="wcamera">
                <div className="text-[12px] md:text-[16px] xl:text-[18px]">
                  DVVR / NVR
                </div>
              </TabsTrigger>
              <TabsTrigger value="wcamera">
                <div className="text-[12px] md:text-[16px] xl:text-[18px]">
                  KABEL
                </div>
              </TabsTrigger>
              <TabsTrigger value="wcamera">
                <div className="text-[12px] md:text-[16px] xl:text-[18px]">
                  HARDISK
                </div>
              </TabsTrigger>
              <TabsTrigger value="wcamera">
                <div className="text-[12px] md:text-[16px] xl:text-[18px]">
                  ADAPTOR / POWER SUPPLY
                </div>
              </TabsTrigger>
              <TabsTrigger value="wcamera">
                <div className="text-[12px] md:text-[16px] xl:text-[18px]">
                  CONECTOR / POE / MIC
                </div>
              </TabsTrigger>
              <TabsTrigger value="wcamera">
                <div className="text-[12px] md:text-[16px] xl:text-[18px]">
                  MATERIAL PENUNJANG
                </div>
              </TabsTrigger>
              <TabsTrigger value="wcamera">
                <div className="text-[12px] md:text-[16px] xl:text-[18px]">
                  MICRO SD
                </div>
              </TabsTrigger>
              <TabsTrigger value="wcamera">
                <div className="text-[12px] md:text-[16px] xl:text-[18px]">
                  INTERNET
                </div>
              </TabsTrigger>
            </TabsList>
            {/* Bagian Konten */}
            <div className="min-h-[60vh] w-full">
              <TabsContent value="hdcvi" className="w-full">
                <Hdcvi />
              </TabsContent>
              <TabsContent value="hilook" className="w-full">
                <Hilook />
              </TabsContent>
              <TabsContent value="hikvision" className="w-full">
                <Hikvision />
              </TabsContent>
              <TabsContent value="dahua" className="w-full">
                <Dahua />
              </TabsContent>
              <TabsContent value="wcamera" className="w-full">
                <Wcamera />
              </TabsContent>
            </div>
          </Tabs>
        </div>
      </motion.div>
    </Layout>
  );
};

export default Product;
