import React from "react";
import Layout from "./Layout";
import { TypeAnimation } from "react-type-animation";
import { motion } from "framer-motion";
import ContactCom from "../components/ContactCom";

const Contact = () => {
  return (
    <Layout>
      <section className="container">
        <div className="container rounded-md">
          <ContactCom />
        </div>
      </section>
    </Layout>
  );
};

export default Contact;
